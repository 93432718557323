<template>
  <div>
    <v-app-bar
      class="elevation-0 gradient-primary"
      height="63px"
      app
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="navigation = !navigation" />
      <v-spacer></v-spacer>
      <a
        v-if="!$vuetify.breakpoint.xsOnly"
        href="https://sistesi.id"
        style="text-decoration: none; color: white"
        class="font-weight-bold caption"
        >Halaman Utama</a
      >
      <v-spacer></v-spacer>
      <v-row
        no-gutters
        class="logout-wraper"
        justify="end"
        align="center"
        @click="$router.push('/')"
      >
        <v-avatar size="35">
          <v-img :src="boyPhoto" />
        </v-avatar>
        <template>
          <div
            class="subtitle-2 d-inline-block text-truncate logout-wraper pl-2 pr-4"
            style="max-width: 200px"
          >
            <strong>Kevin Sanjaya</strong>
          </div>
          <!-- <div class="caption text-capitalize">Siswa</div> -->
        </template>
      </v-row>

      <v-btn icon>
        <v-badge overlap color="green" :content="5">
          <v-icon color="white">mdi-message-text</v-icon>
        </v-badge>
      </v-btn>

      <v-btn icon>
        <v-badge overlap color="green" :content="2">
          <v-icon>mdi-bell-outline</v-icon>
        </v-badge>
      </v-btn>

      <v-btn v-on="on" icon><v-icon>mdi-cog</v-icon></v-btn>
    </v-app-bar>

    <v-navigation-drawer
      v-model="navigation"
      :permanent="$vuetify.breakpoint.mdAndUp"
      class="grey lighten-4"
      mini-variant-width="65"
      app
    >
      <v-list-item>
        <v-list-item-icon>
          <v-img
            src="@/assets/logo.png"
            max-height="100%"
            max-width="30px"
          ></v-img>
        </v-list-item-icon>
        <v-list-item-title class="font-weight-black body-1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">Sekolah Demo</span>
            </template>
            <span>Sekolah Demo</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <span v-for="(item, i) in menuItems" :key="i">
          <v-list-item
            v-if="!item.children"
            :class="isActiveMenu(item.title) ? 'primary elevation-0' : ''"
            :dark="isActiveMenu(item.title)"
            link
            @click="toModule(item)"
          >
            <v-list-item-icon>
              <!-- <v-icon :color="isActiveMenu(item.title) ? 'white' : ''">{{
                item.icon
              }}</v-icon> -->
              <img
                v-if="item.icon"
                class="icon-sidebar"
                :src="iconPath(item.icon)"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else no-action>
            <template slot="prependIcon">
              <img
                v-if="item.icon"
                class="icon-sidebar"
                :src="iconPath(item.icon)"
              />
            </template>
            <template v-slot:activator>
              <v-list-item-content
                :color="isActiveMenu(item.title) ? 'white' : ''"
              >
                <v-list-item-title class="font-weight-bold">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              :class="isActiveMenu(child.title) ? 'primary elevation-0' : ''"
              :dark="isActiveMenu(child.title)"
              link
              @click="toModule(child)"
            >
              <v-list-item-title
                v-text="child.title"
                class="font-weight-bold"
              ></v-list-item-title>
              <v-list-item-icon>
                <img
                  v-if="child.icon"
                  class="icon-sidebar"
                  :src="iconPath(child.icon)"
                />
                <!-- <v-icon
                  v-text="child.icon"
                  :color="isActiveMenu(item.title) ? 'white' : ''"
                ></v-icon> -->
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </span>
      </v-list>
    </v-navigation-drawer>

    <ChangePassword
      :dialogChangePassword="dialogChangePassword"
      :closeDialogChangePassword="() => (dialogChangePassword = false)"
    />

    <v-main app style="background: #fff" class="pb-12">
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { admin } from "../utils/menu";

import ChangePassword from "../components/ChangePassword.vue";

export default {
  components: {
    ChangePassword,
  },
  data() {
    return {
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      menuItems: [],
      right: null,
      navigation: true,
      modalPassword: false,
      dialogChangePassword: false,
    };
  },
  computed: {
    ...mapGetters(["g_app_name", "g_user", "g_roles", "g_active_menu"]),
    isMob() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  created() {
    this.menuItems = admin.filter((d) => !d.hidden);

    if (this.isMob) {
      this.navigation = false;
    }
  },
  methods: {
    iconPath(icon) {
      return require(`@/assets/svg/sidebar/${icon}`);
    },
    toModule(item) {
      this.$store.commit("SET_ACTIVE_MENU", item.title);
      if (this.isMob) this.navigation = !this.navigation;
      if (this.$route.name !== item.name)
        this.$router.push({ name: item.name });
    },
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        window.location.reload();
      });
    },
    isActiveMenu(title) {
      return title === this.g_active_menu;
    },
  },
};
</script>

<style lang="scss">
@mixin active-sidebar() {
  background: white;
  color: black !important;
  box-shadow: 2px 3px 5px #ececec;
}

$checkClass: false;

.logout-wraper {
  cursor: pointer;
}
.icon-sidebar {
  max-width: 24px;
}
.list-bg-sidebar {
  color: black;
}

.v-list-group__header {
  @if $checkClass {
    @include active-sidebar;
  }
}

.v-list-item-group {
  .list-bg-sidebar {
    &.v-list-item--active {
      @include active-sidebar;
    }
  }
  .theme--light {
    &.v-list-item--active:before {
      opacity: 0;
    }
  }
  .v-list-item--active:before {
    opacity: 0;
  }
}
</style>
