import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.dialogChangePassword),callback:function ($$v) {_vm.dialogChangePassword=$$v},expression:"dialogChangePassword"}},[_c(VCard,{staticClass:"rounded-lg"},[_c(VCardText,{staticClass:"mt-3 pb-2"},[_c('div',{staticClass:"title font-weight-bold text-center pt-6"},[_vm._v(" Ganti Password ")]),_c(VForm,{ref:"formChangePassword",staticClass:"mt-3",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"append-icon":_vm.spNew ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.spNew ? 'text' : 'password',"rules":[
                  function (v) { return !!v || 'Password baru harus diisi'; },
                  function (v) { return (!!v && v.length > 7) || 'Password minimal 8 karakter'; } ],"label":"Password Baru","hide-details":"auto","autocomplete":"","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.validate.apply(null, arguments)},"click:append":function($event){_vm.spNew = !_vm.spNew}},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", $$v)},expression:"password.new_password"}})],1)],1)],1),_c('div',{staticClass:"d-flex mb-2 mt-5"},[_c(VBtn,{staticClass:"mr-2",attrs:{"outlined":""},on:{"click":_vm.close}},[_vm._v("Batal")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"warning","disabled":!_vm.valid,"loading":_vm.loadingChangePassword,"depressed":""},on:{"click":_vm.validate}},[_vm._v("Simpan")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }