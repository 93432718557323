import router from "../router";

import { getToken, setToken } from "../utils/storage";

const user = {
  state: {
    roles: "",
    token: getToken(),
    user: {},
    menu: [],
    admin_first_page: "",
    active_menu: "",
  },

  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_USER: (state, value) => {
      state.user = value;
    },
    SET_MENU: (state, value) => {
      state.menu = value;
    },
    SET_ADMIN_FIRST_PAGE: (state, value) => {
      state.admin_first_page = value;
    },
    SET_ACTIVE_MENU: (state, value) => {
      state.active_menu = value;
    },
  },
  actions: {
    LogOut() {
      return new Promise(() => {
        console.log("logout");
      });
    },
    LogOutFe({ commit }) {
      return new Promise((resolve) => {
        commit("SET_GLOBAL_LOADING", true);
        setTimeout(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", "");
          commit("SET_USER", {});
          commit("SET_MENU", {});
          commit("SET_GLOBAL_LOADING", false);
          router.replace({ path: "/login" });
          resolve();
        }, 1000);
      });
    },
  },
};

export default user;
