import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"300"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(_vm._s(_vm.text))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.action(false)}}},[_vm._v(" Batal ")]),_c(VBtn,{attrs:{"color":_vm.isDelete ? 'error' : 'primary',"depressed":""},on:{"click":function($event){return _vm.action(true)}}},[_vm._v(" "+_vm._s(_vm.isDelete ? "Hapus" : "Simpan")+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }