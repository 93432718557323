export const admin = [
  {
    title: "Tahun Ajaran",
    name: "SchoolYear",
    parent: "master",
    hidden: false,
    icon: "sidebar-icon-master-data.svg",
  },
  {
    title: "Akademik",
    icon: "sidebar-icon-academic.svg",
    name: "Academic",
    parent: "academic",
    hidden: false,
    children: [
      {
        title: "Jadwal",
        name: "Schedule",
        parent: "academic",
        hidden: false,
      },
      {
        title: "E-Learning",
        name: "Elearning",
        parent: "academic",
        hidden: false,
      },
      {
        title: "E-Test",
        name: "Etest",
        parent: "academic",
        hidden: false,
      },
      {
        title: "Nilai",
        name: "Score",
        parent: "academic",
        hidden: false,
      },
      {
        title: "Extrakurikuler",
        name: "Extra",
        parent: "academic",
        hidden: false,
      },
      {
        title: "Catatan BK",
        name: "Conseling",
        parent: "academic",
        hidden: false,
      },
    ],
  },
  {
    title: "Absensi",
    icon: "icon_admin absensi.svg",
    name: "Absent",
    parent: "absent",
    hidden: false,
    children: [
      {
        title: "Absensi",
        name: "Absent",
        parent: "absent",
        hidden: false,
      },
      {
        title: "Izin",
        name: "Permission",
        parent: "permission",
        hidden: false,
      },
    ],
  },
  {
    title: "Rapor",
    name: "Rapor",
    parent: "rapor",
    hidden: false,
    icon: "sidebar-icon-recap.svg",
  },
];
