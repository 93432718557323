import router from "./router";
import store from "./store";

router.beforeEach((to, _, next) => {
  store.commit("SET_GLOBAL_LOADING", true);
  next();
});

router.afterEach(() => {
  store.commit("SET_GLOBAL_LOADING", false);
});
