import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import VueClipboard from "vue-clipboard2";

// Components
import ModalConfirm from "./components/ModalConfirm.vue";

import "./global.scss";
import "./permission";
import "./layouts/index";

Vue.use(VueApexCharts);
Vue.use(VueClipboard);

Vue.component("ModalConfirm", ModalConfirm);
Vue.component("VueApexChart", VueApexCharts);

import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
