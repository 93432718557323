<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="action(false)"> Batal </v-btn>
        <v-btn
          :color="isDelete ? 'error' : 'primary'"
          depressed
          @click="action(true)"
        >
          {{ isDelete ? "Hapus" : "Simpan" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    title: String,
    text: String,
    type: String,
  },
  computed: {
    isDelete() {
      return this.type === "delete";
    },
  },
  methods: {
    action(value) {
      this.$emit("action", value);
    },
  },
};
</script>
