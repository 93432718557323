<template>
  <v-app style="position: relative">
    <v-overlay absolute opacity="0.4" z-index="1000" :value="globalLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <snackbar></snackbar>

    <component :is="layout">
      <router-view />
    </component>

    <div v-if="!$vuetify.breakpoint.xsOnly" id="alert-marketing">
      <div class="d-flex">
        <div class="d-flex align-start">
          <v-icon class="mr-2" color="white">mdi-information-outline</v-icon>
          Untuk informasi lebih lanjut, hubungi marketing kami di
        </div>
        <div class="d-flex align-start ml-6">
          <a href="mailto:info@sistesi.id">
            <v-icon class="mr-2" color="white">mdi-email</v-icon>
            info@sistesi.id
          </a>
        </div>
        <div class="d-flex align-start ml-6">
          <a href="tel:+62217669525">
            <v-icon class="mr-2" color="white">mdi-phone</v-icon>
            021 766-9525
          </a>
        </div>
      </div>
    </div>

    <div v-if="$vuetify.breakpoint.xsOnly" id="alert-marketing">
      <div class="d-flex justify-center pa-2" style="width: 90%">
        <div class="d-flex align-start mr-2">
          <a href="mailto:info@sistesi.id">
            <v-icon class="mr-2" color="white">mdi-email</v-icon>
            info@sistesi.id
          </a>
        </div>
        <div class="d-flex align-start">
          <a href="tel:+62217669525">
            <v-icon class="mr-2" color="white">mdi-phone</v-icon>
            021 766-9525
          </a>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    Snackbar,
  },
  data: () => ({
    defaultLayout: "default",
  }),
  computed: {
    globalLoading() {
      return this.$store.getters.g_global_loading;
    },
    layout() {
      return `${this.$route.meta.layout || this.defaultLayout}-layout`;
    },
  },
};
</script>

<style lang="scss">
#alert-marketing {
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100vw;
  background: #5559a5;
  color: white;
  z-index: 100;
  min-height: 75px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: white;
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
