import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Profile",
    meta: { layout: "admin", roles: ["admin"], parent: "profile" },
    component: () => import("../views/profile.vue"),
  },
  {
    path: "/school-year",
    name: "SchoolYear",
    meta: { layout: "admin", roles: ["admin"], parent: "SchoolYear" },
    component: () => import("../views/schoolyear.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/schedule.vue"),
  },
  {
    path: "/e-learning",
    name: "Elearning",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/elearning/elearning.vue"),
  },
  {
    path: "/e-learning-content-list",
    name: "Elearning",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () =>
      import("../views/academic/elearning/elearning-content-list.vue"),
  },
  {
    path: "/e-learning-content",
    name: "Elearning",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () =>
      import("../views/academic/elearning/elearning-content.vue"),
  },
  {
    path: "/e-test",
    name: "Etest",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/etest/etest.vue"),
  },
  {
    path: "/e-test-detail",
    name: "EtestDetail",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/etest/etest-detail.vue"),
  },
  {
    path: "/score",
    name: "Score",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/score.vue"),
  },
  {
    path: "/extra",
    name: "Extra",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/extra.vue"),
  },
  {
    path: "/conseling",
    name: "Conseling",
    meta: { layout: "admin", roles: ["admin"], parent: "academic" },
    component: () => import("../views/academic/conseling.vue"),
  },
  {
    path: "/absent",
    name: "Absent",
    meta: { layout: "admin", roles: ["admin"], parent: "absent" },
    component: () => import("../views/absent/absent.vue"),
  },
  {
    path: "/permission",
    name: "Permission",
    meta: { layout: "admin", roles: ["admin"], parent: "absent" },
    component: () => import("../views/absent/permission/permission.vue"),
  },
  {
    path: "/permission-student",
    name: "PermissionStudent",
    meta: { layout: "admin", roles: ["admin"], parent: "absent" },
    component: () =>
      import("../views/absent/permission/permission-student.vue"),
  },
  {
    path: "/rapor",
    name: "Rapor",
    meta: { layout: "admin", roles: ["admin"], parent: "rapor" },
    component: () => import("../views/rapor.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Profile",
    meta: { layout: "admin", roles: ["admin"], parent: "profile" },
    component: () => import("../views/profile.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
