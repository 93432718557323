import request from "../utils/request";

export function userLogin(username, password) {
  return request({
    method: "post",
    url: "/auth/login",
    data: {
      username,
      password,
    },
  });
}

export function userProfile(token) {
  return request({
    method: "post",
    url: "/auth/profile",
    headers: { Authorization: `bearer ${token}` },
  });
}

export function userLogout() {
  return request({
    method: "post",
    url: "/auth/logout",
  });
}

export function userChangePassword(data) {
  return request({
    method: "post",
    url: "/auth/change-password",
    data,
  });
}

export function userChangePasswordNoAuth(data) {
  return request({
    method: "post",
    url: "/auth/update-password",
    data,
  });
}

export function userForgotPassword(data) {
  return request({
    method: "post",
    url: "/auth/forgot-password",
    data,
  });
}
