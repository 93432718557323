import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAppBar,{staticClass:"elevation-0 gradient-primary",attrs:{"height":"63px","app":"","dense":"","dark":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.navigation = !_vm.navigation}}}),_c(VSpacer),(!_vm.$vuetify.breakpoint.xsOnly)?_c('a',{staticClass:"font-weight-bold caption",staticStyle:{"text-decoration":"none","color":"white"},attrs:{"href":"https://sistesi.id"}},[_vm._v("Halaman Utama")]):_vm._e(),_c(VSpacer),_c(VRow,{staticClass:"logout-wraper",attrs:{"no-gutters":"","justify":"end","align":"center"},on:{"click":function($event){return _vm.$router.push('/')}}},[_c(VAvatar,{attrs:{"size":"35"}},[_c(VImg,{attrs:{"src":_vm.boyPhoto}})],1),[_c('div',{staticClass:"subtitle-2 d-inline-block text-truncate logout-wraper pl-2 pr-4",staticStyle:{"max-width":"200px"}},[_c('strong',[_vm._v("Kevin Sanjaya")])])]],2),_c(VBtn,{attrs:{"icon":""}},[_c(VBadge,{attrs:{"overlap":"","color":"green","content":5}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-message-text")])],1)],1),_c(VBtn,{attrs:{"icon":""}},[_c(VBadge,{attrs:{"overlap":"","color":"green","content":2}},[_c(VIcon,[_vm._v("mdi-bell-outline")])],1)],1),_c(VBtn,_vm._g({attrs:{"icon":""}},_vm.on),[_c(VIcon,[_vm._v("mdi-cog")])],1)],1),_c(VNavigationDrawer,{staticClass:"grey lighten-4",attrs:{"permanent":_vm.$vuetify.breakpoint.mdAndUp,"mini-variant-width":"65","app":""},model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}},[_c(VListItem,[_c(VListItemIcon,[_c(VImg,{attrs:{"src":require("@/assets/logo.png"),"max-height":"100%","max-width":"30px"}})],1),_c(VListItemTitle,{staticClass:"font-weight-black body-1"},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("Sekolah Demo")])]}}])},[_c('span',[_vm._v("Sekolah Demo")])])],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_vm.menuItems),function(item,i){return _c('span',{key:i},[(!item.children)?_c(VListItem,{class:_vm.isActiveMenu(item.title) ? 'primary elevation-0' : '',attrs:{"dark":_vm.isActiveMenu(item.title),"link":""},on:{"click":function($event){return _vm.toModule(item)}}},[_c(VListItemIcon,[(item.icon)?_c('img',{staticClass:"icon-sidebar",attrs:{"src":_vm.iconPath(item.icon)}}):_vm._e()]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])],1)],1):_c(VListGroup,{attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,{attrs:{"color":_vm.isActiveMenu(item.title) ? 'white' : ''}},[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},[_c('template',{slot:"prependIcon"},[(item.icon)?_c('img',{staticClass:"icon-sidebar",attrs:{"src":_vm.iconPath(item.icon)}}):_vm._e()]),_vm._l((item.children),function(child,i){return _c(VListItem,{key:i,class:_vm.isActiveMenu(child.title) ? 'primary elevation-0' : '',attrs:{"dark":_vm.isActiveMenu(child.title),"link":""},on:{"click":function($event){return _vm.toModule(child)}}},[_c(VListItemTitle,{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(child.title)}}),_c(VListItemIcon,[(child.icon)?_c('img',{staticClass:"icon-sidebar",attrs:{"src":_vm.iconPath(child.icon)}}):_vm._e()])],1)})],2)],1)}),0)],1),_c('ChangePassword',{attrs:{"dialogChangePassword":_vm.dialogChangePassword,"closeDialogChangePassword":function () { return (_vm.dialogChangePassword = false); }}}),_c(VMain,{staticClass:"pb-12",staticStyle:{"background":"#fff"},attrs:{"app":""}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }